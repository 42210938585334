<template>
  <div>
    <CRow>
      <CCol sm="12">
        <GamblersTable
          :raffle="raffle"
          :items="gamblers"
          :availables="availables"
          :paid="paid"
          :reserved="reserved"
          hover
          striped
          border
          small
          fixed
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import GamblersTable from '../../components/administrator/raffles/GamblersTable.vue'
import raffles from '../../services/raffles';
import store from '../../store'

export default {
  name: 'Gamblers',
  components: { GamblersTable },
  data: function () {
		return {
            raffle: false,
            availables: 0,
            paid: 0,
            reserved: 0,
            gamblers: []
        }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let response = await raffles.getRaffle(this.code);

    if(response.type == "success"){
        this.raffle = response.data;
    }

    let response2 = await raffles.getGamblers(this.code);

    if(response2.type == "success"){
      this.gamblers = response2.data;

      this.gamblers.forEach(async (gambler) => {
        if(gambler.status == 'available'){
          this.availables++;
        }

        if(gambler.status == 'reserved'){
          this.reserved++;
        }

        if(gambler.status == 'paid'){
          this.paid++;
        }
      });
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.raffles = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
