<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <div class="card-header-actions">
          Boletos Reservados: {{reserved}}
          <br />
          Boletos Confirmados: {{paid}} ( {{paid / raffle.tickets * 100 | decimal}}% )
        </div>
        <a href="/#/control/raffles"><b>{{raffle.name}}</b></a><br />Boletos Participantes ( Disponibles: {{availables}} )
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 100 : 50"
        :dark="dark"
        pagination
      >
        <template #numbers="{item}"><td class="text-center w300">{{item.numbers}}</td></template>
        <template #gambler_name="{item}"><td class="text-center">{{item.gambler_id > 0 ? item.gambler_name : '-'}}</td></template>
        <template #status="{item}"><td class="text-center w300">{{item.status == 'available' ? '-' : (item.status == 'reserved' ? 'RESERVADO' : (item.status == 'paid' ? 'CONFIRMADO' : '-'))}}</td></template>
      </CDataTable>
    </CCardBody>
    <RaffleModal ref="raffleModal" @store="storeProcess" @update="updateProcess"></RaffleModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import RaffleModal from './RaffleModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'
import ws from '../../../services/raffles';
import store from '../../../store'

export default {
  name: 'GamblersTable',
  components: { RaffleModal, DeleteModal },
  props: {
    items: Array,
    raffle: Object,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'numbers', label: 'Números del Boleto'},
          {key: 'gambler_name', label: 'Participante'},
          {key: 'status', label: 'Estado del Boleto'}
        ]
      }
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    availables: 0,
    reserved: 0,
    paid: 0,
  },
  data() {
    return {
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
